<template>
  <b-container class="mb-7">
    <b-row class="sidebar-margin" v-if="!eventReady && errorMessage">
      <b-col class="flex justify-center items-center">
        <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
      </b-col>
    </b-row>
    <b-row class="sidebar-margin" v-else-if="!eventReady">
      <b-col class="flex justify-center items-center">
        <RbLoadingSpinner />
      </b-col>
    </b-row>
    <div class="sidebar-margin" v-else>
      <div class="flex flex-row flex-wrap sm:w-10/12 sm:m-auto md:w-11/12 lg:m-0">
        <div class="flex flex-col flex-grow justify-center max-w-full xs:ml-0 xs:mt-3">
          <h1 class="flex font-header font-bold -mb-4 xs:text-3xl">{{ event.name }}</h1>
          <StatusBadge :status="event.status" :raffleType="event.category" />
          <div class="flex flex-row">
            <div class="mt-6 w-fit">
              <p class="text-lg font-semibold">Starting Pot</p>
              <p class="text-left font-normal text-lg mt-1">
                {{ formatCurrency(event.startingPotCents / 100) }}
              </p>
            </div>
            <div class="mt-6 mx-6 w-fit">
              <p class="text-lg font-semibold">Jackpot</p>
              <p class="text-left font-normal text-lg mt-1">
                {{ formatCurrency(event.actualJackpotCents / 100) }}
              </p>
            </div>
            <div class="mt-6 mr-6 w-fit">
              <p class="text-lg font-semibold">Stripe Fees</p>
              <p class="text-left font-normal text-lg mt-1">
                {{ formatCurrency(stripeFees) }}
              </p>
            </div>
            <div class="mt-6 w-fit">
              <p class="text-lg font-semibold">Draw</p>
              <p class="text-left font-normal text-lg mt-1">{{ event.drawNumber }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Total Raised / Progress Bar -->
      <b-row>
        <b-col class="m-auto">
          <div class="flex flex-col">
            <div class="total-raised xs:mt-10 sm:mt-16 md:mt-20 max-w-7xl">
              <div class="flex flex-row xs:mb-5 lg:mb-10 justify-center items-baseline">
                <img
                  src="@/assets/monogram-green.svg"
                  width="42"
                  height="42"
                  alt="Rafflebox logo icon"
                  class="flex self-center"
                />

                <number
                  :from="0"
                  :to="event.jackpot"
                  :duration="1"
                  :format="formatTotalRaised"
                  :delay="0.5"
                  easing="Power1.easeOut"
                  class="text-4xl font-bold ml-3 mb-0"
                />
                <p class="font-bold ml-3 mb-0 flex self-baseline">raised</p>
              </div>
              <RaffleProgressBar
                :progress="this.progress"
                startTitle="Start Date"
                :startSubtitle="formatStartEndDates(event.startDate)"
                endTitle="End Date"
                :endSubtitle="formatStartEndDates(event.endDate)"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- Total Cards -->
      <b-row class="mt-8">
        <b-col class="flex xs:flex-col xs:items-center lg:flex-row lg:justify-evenly max-w-7xl">
          <IconCard
            iconName="ticket"
            cardTitle="Total Tickets"
            :cardSubtitle="digitGroupSeparator(event.totalTickets)"
            :eventId="event.id"
            urlBase="tickets"
            linkLabel="View tickets"
          />
          <IconCard
            iconName="receipt"
            cardTitle="Total Orders"
            :cardSubtitle="digitGroupSeparator(event.totalOrders)"
            :eventId="event.id"
            urlBase="orders"
            linkLabel="View orders"
            class="xs:my-8 sm:mx-4"
          />
          <IconCard
            iconName="money-bill-transfer"
            cardTitle="Total Refunds"
            :cardSubtitle="digitGroupSeparator(totalRefunds)"
            :eventId="event.id"
            urlBase="orders"
            urlParams="status=VOID"
            linkLabel="View refunds"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mt-12 mb-16">
          <GoldrushSalesReport :eventId="event.id" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import Alert from '@/components/ui/Alert';
import StatusBadge from '@/components/ui/StatusBadge';
import IconCard from '@/components/ui/IconCard';
import RaffleProgressBar from '@/components/ui/RaffleProgressBar';
import GoldrushSalesReport from '@/components/reports/GoldrushSalesReport';
import EventServiceV2 from '@/lib/event-service-v2';
import OrderServiceV2 from '@/lib/order-service-v2';
import { format, differenceInDays, startOfToday, isBefore, isPast } from 'date-fns';

export default {
  components: {
    Alert,
    StatusBadge,
    IconCard,
    RaffleProgressBar,
    GoldrushSalesReport
  },
  data() {
    return {
      errorMessage: null,
      event: {},
      eventReady: false,
      totalRefunds: 0,
      progress: null,
      updateSuccess: false,
      updateSuccessMessage: '',
      loading: false,
      disablePickWinnerButton: false,
      stripeFees: 0
    };
  },
  async beforeMount() {
    await this.getEvent();
    await this.getRefunds();
    await this.getReport();
  },
  computed: {
    salesReport() {
      return this.$store.state.eventSalesReport.data;
    }
  },
  methods: {
    digitGroupSeparator(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    async getEvent() {
      try {
        const eventId = this.$route.query.eventId ? this.$route.query.eventId : this.$route.params.id;
        const eventData = await EventServiceV2.retrieveEvent(eventId);

        this.event = eventData;

        this.progress = this.getRaffleProgress();

        this.eventReady = true;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    async getReport() {
      try {
        await this.$store.dispatch('getEventSales', this.event.id);
        this.stripeFees = this.salesReport.processingFees.stripeFeesCents / 100;
      } catch (error) {
        this.errorMessage = `Failed to load Sales Report: ${error.message}`;
      }
    },
    async getRefunds() {
      try {
        const params = {
          eventId: this.event.id,
          pageSize: 100,
          status: 'VOID'
        };

        const orders = await OrderServiceV2.listOrders(params);

        this.totalRefunds = orders.data.length;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },
    formatStartEndDates(date) {
      if (date) {
        return format(this.parseISO(date), 'MMM d');
      }
    },
    getRaffleProgress() {
      const startDate = this.parseISO(this.event.startDate);
      const endDate = this.parseISO(this.event.endDate);
      const daysBetween = differenceInDays(endDate, startDate);
      const today = startOfToday();
      const daysElapsed = differenceInDays(today, startDate);
      const progress = daysElapsed / daysBetween;

      if (isPast(endDate)) {
        return 100;
      } else if (isBefore(today, startDate)) {
        return 0;
      } else if (progress === 0) {
        // So the progress bar doesn't appear empty on Day 1 of the raffle.
        return 5;
      }

      return progress * 100;
    },
    formatTotalRaised(number) {
      return `${this.formatCurrency(number, { precision: 0 })}`;
    }
  }
};
</script>

<style lang="scss">
.text-link {
  color: #2c3e50;

  &:hover {
    color: #2c3e50;
  }
}
</style>

<style lang="scss" scoped>
.min-width {
  min-width: 20rem;
}
</style>
