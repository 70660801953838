<template>
  <div class="sm:mx-2 md:max-w-7xl">
    <div class="flex justify-center" v-if="loading && !error">
      <RbLoadingSpinner />
    </div>
    <div v-else>
      <div>
        <div class="flex mb-3 justify-between xs:items-baseline">
          <h1 class="text-2xl font-medium mb-0">Station Sales</h1>
          <DownloadReportButton @onClick="downloadReport" :downloadingReport="downloadingReport" />
        </div>
      </div>
      <div v-if="!loading && error">
        <Alert v-if="error" variant="red" icon="exclamation">{{ error }}</Alert>
      </div>
      <div class="max-w-full overflow-x-scroll" v-else>
        <table class="min-w-full divide-y divide-gray-300">
          <caption class="sr-only">
            Goldrush Sales by Station Report
          </caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="py-3 pl-4 pr-3 text-left text-xs font-bold tracking-wide text-gray-900 sm:pl-6">
                Station Name
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Series A
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Series B
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Series C
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Series D
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Series E
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-19">
                Current Sales
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-46">
                Future Sales
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-46">
                Refunds
              </th>
              <th scope="col" class="px-3 py-3 text-right text-xs font-bold tracking-wide text-gray-900 w-46">
                Total Sales
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="station in salesReport.items" :key="station.name">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {{ station.stationName }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">{{ station.seriesA }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">{{ station.seriesB }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">{{ station.seriesC }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">{{ station.seriesD }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">{{ station.seriesE }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(station.currentSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(station.futureSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(station.refundedSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right">
                {{ formatCurrency(station.totalSalesCents / 100) }}
              </td>
            </tr>
            <tr>
              <td v-if="salesReport.eventId && salesReport"></td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ salesReport.totals.seriesA }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ salesReport.totals.seriesB }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ salesReport.totals.seriesC }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ salesReport.totals.seriesD }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ salesReport.totals.seriesE }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ formatCurrency(salesReport.totals.currentSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ formatCurrency(salesReport.totals.futureSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ formatCurrency(salesReport.totals.refundedSalesCents / 100) }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 text-right font-bold">
                {{ formatCurrency(salesReport.totals.totalSalesCents / 100) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import DownloadReportButton from '@/components/ui/DownloadReportButton';
import ReportServiceV2 from '@/lib/report-service-v2';
import { downloadFormattedCSV } from '@/lib/download-file';

export default {
  components: {
    Alert,
    DownloadReportButton
  },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      downloadingReport: false
    };
  },
  async mounted() {
    await this.getReport();
  },
  computed: {
    salesReport() {
      return this.$store.state.goldrushStationSalesReport.report;
    },
    loading() {
      return this.$store.state.goldrushStationSalesReport.loading;
    },
    error() {
      return this.$store.state.goldrushStationSalesReport.error;
    }
  },
  methods: {
    async getReport() {
      await this.$store.dispatch('getGoldrushStationReportSales', this.eventId);
    },
    async downloadReport() {
      this.downloadingReport = true;

      try {
        const data = await ReportServiceV2.goldrushStationSalesReport(this.eventId, {}, 'text/csv');

        downloadFormattedCSV(`goldrush_station_sales_${Date.now()}.csv`, data);
      } catch (error) {
        const formattedError = this.parseError(error).message;
        this.$store.commit('SET_ERROR', formattedError);
      }

      this.downloadingReport = false;
    }
  }
};
</script>
